import { isEmpty } from "lodash-es"
import { required } from "vuelidate/lib/validators"

export const defaultPriceTableObject = () => ({
  name: null,
  time_slot_id: null,
  price_rows: []
})

export const buildPricesValidator = priceKeys => {
  const validator = {}

  if (isEmpty(priceKeys)) {
    return validator
  }

  validator[priceKeys[0]] = {
    required,
    minValue: value => value > 0
  }

  for (let i = 1; i < priceKeys.length; i++) {
    const currentKey = priceKeys[i]
    if (currentKey.includes("after")) {
      validator[currentKey] = {
        required,
        minValue: value => value > 0
      }
    } else {
      const prevKey = priceKeys[i - 1]
      validator[currentKey] = {
        required,
        greaterThanCheck: (value, self) => value > self?.[prevKey]
      }
    }
  }

  return validator
}
