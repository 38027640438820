import { minLength, required } from "vuelidate/lib/validators"
import { buildPricesValidator } from "../../../helpers"

const buildOptionsValidator = useOptions => {
  return useOptions ? { cancellation_fee_id: { required } } : {}
}

export const priceTableValidator = (priceKeys, useOptions = false) => ({
  price_rows: {
    required,
    minLength: minLength(1),
    $each: {
      id: { required: false },
      car_class_id: { required },
      ...buildOptionsValidator(useOptions),
      prices: buildPricesValidator(priceKeys)
    }
  }
})
