<template lang="pug">
  .form-row
    .price-table
      template(v-if="!isEdit")
        template(v-if="usePriceTable")
          .form-row
            .form-row-label
              FormFieldLabel(
                :title="$t('company_system.rate_plan_settings.rate_plan.details.car_class_settings.price_table')"
                required
              )
            .form-row-field
              AppDropdown(
                allow-empty
                close-on-select
                :disabled="isEdit"
                :items="priceTables"
                :value="selectedPriceTable"
                @select="selectPriceTable"
              )
        template(v-if="useTimeSlot")
          .form-row
            .form-row-label
              FormFieldLabel(
                :title="$t('company_system.rate_plan_settings.rate_plan.details.car_class_settings.card.rental_period_type')"
                required
              )
            .form-row-field
              AppDropdown(
                allow-empty
                close-on-select
                :class="{ 'is-invalid': $v.selectedTimeSlotType.$error }"
                :value="selectedTimeSlot"
                :disabled="!isTimeSlotSelectable || isEdit"
                :items="timeSlots"
                @select="selectTimeSlot"
              )
      .car-classes.flex-row
        .car-classes-bar.flex-row
          .car-classes-bar-item(v-for="(priceRow, index) in $v.priceTable.price_rows.$each.$iter")
            CarClassCard(
              :key="priceRow.$model.id || priceRow.$model._addedAt"
              :price-row="priceRow"
              :car-classes="carClasses"
              :selected-car-class-ids="selectedCarClassIds"
              :cancellation-fees="cancellationFees"
              :standard-options="standardOptions"
              :selectable-options="selectableOptions"
              :use-options="useOptions"
              :use-price-table="usePriceTable"
              :time-slot-type="selectedTimeSlotType"
              :clone-disabled="isAddNewPriceRowDisabled"
              @select-car-class="selectPriceRowCarClass($event, index)"
              @change="updatePriceRow(index, $event)"
              @delete="$emit('delete-price-row', index)"
              @clone="$emit('clone-price-row', index)"
            )
        .add-car-class
          AppIconButton.add-car-class-action(
            :disabled="isAddNewPriceRowDisabled"
            icon="plus-circle"
            @click="addNewPlanPriceRow"
          )
          .label
            span {{ $t('company_system.rate_plan_settings.rate_plan.details.car_class_settings.add_car_class') }}
    BFormInvalidFeedback(
      v-if="hasEmptyPriceRowsError"
      :state="false"
    )
      | {{ $t('company_system.rate_plan_settings.rate_plan.details.car_class_settings.at_least_1_car_class') }}
</template>

<script>
  // misc
  import { isEmpty, map, reduce, get, find } from "lodash-es"
  import { emptyPriceRowObject } from "./../helpers"
  import { required } from "vuelidate/lib/validators"
  import { validationMixin } from "vuelidate"
  import { priceTableValidator } from "./validators"
  import { bus } from "@/config"

  export default {
    components: {
      CarClassCard: () => import("./CarClassCard"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    mixins: [validationMixin],

    props: {
      priceTable: {
        type: Object,
        default: () => {}
      },
      usePriceTable: {
        type: Boolean,
        default: true
      },
      useTimeSlot: {
        type: Boolean,
        default: true
      },
      useOptions: {
        type: Boolean,
        default: true
      },
      selectedTimeSlot: {
        type: Object,
        default: () => {}
      },
      selectedTimeSlotType: {
        type: String,
        default: undefined
      },
      isTimeSlotSelectable: {
        type: Boolean,
        default: true
      },
      priceKeys: {
        type: Array,
        default: () => []
      },
      priceTables: {
        type: Array,
        default: () => []
      },
      carClasses: {
        type: Array,
        default: () => []
      },
      timeSlots: {
        type: Array,
        default: () => []
      },
      cancellationFees: {
        type: Array,
        default: () => []
      },
      standardOptions: {
        type: Array,
        default: () => []
      },
      selectableOptions: {
        type: Array,
        default: () => []
      }
    },

    mounted() {
      bus.on(`validate-${this.$vnode.key}`, this.validateAttributes)
      bus.on(`cancel-${this.$vnode.key}-validation`, this.cancelValidation)
    },

    beforeDestroy() {
      bus.off(`validate-${this.$vnode.key}`, this.validateAttributes)
      bus.off(`cancel-${this.$vnode.key}-validation`, this.cancelValidation)
    },

    validations() {
      return {
        priceTable: priceTableValidator(this.priceKeys, this.useOptions),
        selectedTimeSlotType: { required }
      }
    },

    data() {
      return {
        selectedPriceTable: null
      }
    },

    watch: {
      usePriceTable: {
        handler() {
          this.selectPriceTable(null)
        }
      },

      selectedTimeSlot() {
        if (!isEmpty(this.selectedPriceTable)) {
          this.setPriceObjectsFromTable()
        } else {
          this.setPriceObjects()
        }
      },

      isInvalid() {
        this.setPriceTableInvalid()
      }
    },

    computed: {
      isInvalid() {
        return this.$v.$invalid
      },

      isEdit() {
        return !!get(this.priceTable, "id")
      },

      hasEmptyPriceRowsError() {
        return this.$v.priceTable.price_rows.$error && !this.$v.priceTable.price_rows.required
      },

      basicPriceRows() {
        return get(this.priceTable, "price_rows", [])
      },

      isAddNewPriceRowDisabled() {
        return this.selectedCarClassIds.length >= this.carClasses.length
      },

      selectedCarClassIds() {
        return map(this.basicPriceRows, "car_class_id")
      },

      pricesObject() {
        return reduce(
          this.priceKeys,
          (obj, key) => {
            obj[key] = null

            return obj
          },
          {}
        )
      }
    },

    methods: {
      validateAttributes() {
        this.$v.$touch()
        this.setPriceTableInvalid()
      },

      cancelValidation() {
        this.$v.$reset()
      },

      selectPriceRowCarClass({ id }, index) {
        const priceRow = this.basicPriceRows[index]
        this.updatePriceRow(index, { ...priceRow, car_class_id: id })
        if (!isEmpty(this.selectedPriceTable)) {
          this.setPriceObjectFromTable(index, id)
        }
      },

      setPriceObjectsFromTable() {
        this.basicPriceRows.forEach(({ car_class_id }, index) => this.setPriceObjectFromTable(index, car_class_id))
      },

      setPriceObjectFromTable(index, carClassId) {
        const tablePriceObject = this.findTablePriceObject(carClassId)
        if (!isEmpty(tablePriceObject)) {
          const priceRow = this.basicPriceRows[index]
          this.updatePriceRow(index, { ...priceRow, prices: { ...tablePriceObject } })
        } else {
          this.setPriceObject(index)
        }
      },

      findTablePriceObject(carClassId) {
        return find(this.selectedPriceTable.price_rows, ({ car_class_id }) => car_class_id === carClassId)?.prices
      },

      setPriceTableInvalid() {
        this.updatePriceTable({ invalid: this.isInvalid })
      },

      setPriceObjects() {
        this.basicPriceRows.forEach(({ id }, index) => {
          if (id === undefined) {
            this.setPriceObject(index)
          }
        })
      },

      setPriceObject(index) {
        const priceRow = this.basicPriceRows[index]
        this.updatePriceRow(index, { ...priceRow, prices: { ...this.pricesObject } })
      },

      selectTimeSlot({ id }) {
        this.$emit("set-time-slot-id", id)
      },

      selectPriceTable(priceTable) {
        this.selectedPriceTable = priceTable
        this.$emit("set-time-slot-id", priceTable?.time_slot_id)
      },

      addNewPlanPriceRow() {
        this.$emit("add-price-row", emptyPriceRowObject({ ...this.pricesObject }))
      },

      updatePriceTable(changes) {
        this.$emit("update-price-table", { ...this.priceTable, ...changes })
      },

      updatePriceRow(index, item) {
        this.$emit("update-price-row", { index, item })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"
  @import "@/assets/styles/variables.sass"

  .flex-row
    display: flex
    justify-content: left

  .form-row
    display: flex
    flex-direction: column
    margin-top: 20px

    &-field
      .app-select
        background-color: $default-gray-light

    &-label
      font-style: normal
      font-weight: 200
      font-size: 13px
      line-height: 19px

    .price-table
      padding: 20px
      background-color: $default-gray-light
      min-height: 200px
      max-width: 1130px
      margin-right: 20px
      overflow-x: auto

      .car-classes
        margin-top: 20px
        align-items: center
        width: 100%

        &-bar
          &-item
            margin-right: 20px

            &:last-child
              margin-right: 0

        .add-car-class
          display: flex
          flex-direction: column
          width: 110px
          color: $default-purple
          font-weight: 100
          font-size: 10px

          button
            margin: 0 20px

          .label
            margin: 0 auto
            text-align: center

          &-action
            +icon-button($default-purple)
            width: 60px
            height: 60px
            margin: 5px auto

            ::v-deep
              svg
                width: 60px
                height: 60px
</style>
